import React, { useState, useEffect } from "react";
import {
    APIGATEWAY,
    CHECKAUTH_ENDPOINT,
    LOGOUT_ENDPOINT,
} from "../../constants/AppConfig";
import HttpHelper from "../../helpers/HttpHelper";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { } from "../../constants/AppConfig";

function LoginSuccess() {
    const auth = useAuth();
    const navigate = useNavigate();

    //const navToSmsTest = () => {
    //    navigate("/sendTestSms");
    //};

    const logOut = () => {
        let helper = new HttpHelper();
        let logOutUrl = APIGATEWAY + LOGOUT_ENDPOINT;

        helper
            .fetchJson(logOutUrl, "POST", "", document.cookie)
            .then(function (logOutResponse) {
                if (logOutResponse.ok) {
                    document.cookie =
                        "IdentiTeamToken= ; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    document.cookie =
                        "IdentiTeamRefreshToken= ; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                    navigate("/");
                } else {
                    alert("logout failed");
                }
            });
    };

    const resetPassword = () => {
        navigate("/resetPassword");
    };

    const userUpdate = () => {
        navigate("/update");
    };

    const [claimsIndo, setClaimsInfo] = useState("claim info not received");
    const [greeting, setGreeting] = useState("not logged in");
    const [userinfo, setUserInfo] = useState("user info not set");
    const [isloggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        console.log("loginSuccess reached");
        let helper = new HttpHelper();
        let checkauthUrl = APIGATEWAY + CHECKAUTH_ENDPOINT;

        let jwtVal;
        if (document.cookie) {
            jwtVal = document.cookie
                .split("; ")
                .find((row) => row.startsWith("IdentiTeamToken="))
                ?.split("=")[1];
        }

        if (
            (jwtVal === undefined || jwtVal === "undefined") &&
            auth.isAuthenticated
        ) {
            document.cookie = "IdentiTeamToken=" + auth.user.access_token;
            document.cookie = "IdentiTeamRefreshToken=" + auth.user.refresh_token;
        }

        helper
            .fetchJson(checkauthUrl, "GET", "", document.cookie)
            .then(function (checkAuthResponse) {
                if (checkAuthResponse.ok) {
                    setIsLoggedIn(true);
                    setGreeting("You are logged in");
                    checkAuthResponse.json().then(function (jsr) {
                        setUserInfo(JSON.stringify(jsr));
                        setClaimsInfo(JSON.stringify(jsr));
                    });
                }
            });
    });

    return (
        <div className="container">
            <h2>{greeting}</h2>

            <div className="row">
                <h2>Claims</h2>
            </div>
            <div className="row text-left">{claimsIndo}</div>
            <div className="row">
                <h2>Profile</h2>
            </div>
            <div className="row text-left">{userinfo}</div>
            <h2>&nbsp;</h2>
            <div className="row">

                <button className="btn btn-primary" onClick={logOut}>
                    Log Out
                </button>

                <button className="btn btn-secondary" onClick={resetPassword}>
                    Reset Password
                </button>

                <button className="btn btn-info" onClick={userUpdate}>
                    User Update
                </button>
            </div>
        </div>
    );
}

export default LoginSuccess;
