import React, { useState } from "react";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authorizerService from "../../services/authorizer.service";


class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            componentTypes: [],
            selectedComponentTypeId: 0,
            selectedComponentTypeName: "",
            enteredComponentName: "",
            enteredComponentShortName: "",
            enteredComponentDescription: "",
            enteredComponentFeature: "",
            enteredComponentBundle: "",
            enteredComponentGroup: "",
            enteredComponentAction: "",
            enteredComponentLabel: "",
            enteredComponentDisplaySequence: 0,

        };
        this.changeComponentType = this.changeComponentType.bind(this);
        this.changeComponentName = this.changeComponentName.bind(this);
        this.changeComponentShortName = this.changeComponentShortName.bind(this);
        this.changeComponentDescription = this.changeComponentDescription.bind(this);
        this.changeComponentFeature = this.changeComponentFeature.bind(this);
        this.changeComponentBundle = this.changeComponentBundle.bind(this);
        this.changeComponentGroup = this.changeComponentGroup.bind(this);
        this.changeComponentAction = this.changeComponentAction.bind(this);
        this.changeComponentLabel = this.changeComponentLabel.bind(this);
        this.changeComponentDisplaySequence = this.changeComponentDisplaySequence.bind(this);
        this.notify = this.notify.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.submitForm = this.submitForm.bind(this);

    }

    componentDidMount() {
        this.retrieveComponentTypes();
    }

    retrieveComponentTypes() {
        authorizerService.getComponentTypes()
            .then(response => {
                const jsonData = response.data.Data;
                const options = jsonData.map(d => { return { label: d.Name, value: d.Id } });
                this.setState({
                    ComponentTypes: options
                });

            })
            .catch(e => {
                this.notify(e.response.status, e.message);
                console.log(e);
            });
    }

    changeComponentType(selectedComponentTypes) {
        this.setState({ selectedComponentTypeId: selectedComponentTypes.value });
        this.setState({ selectedComponentTypeName: selectedComponentTypes.label });
    }

    changeComponentName(event) {
        this.setState({ enteredComponentName: event.target.value });
    }

    changeComponentShortName(event) {
        this.setState({ enteredComponentShortName: event.target.value });
    }

    changeComponentDescription(event) {
        this.setState({ enteredComponentDescription: event.target.value });
    }

    changeComponentFeature(event) {
        this.setState({ enteredComponentFeature: event.target.value });
    }

    changeComponentBundle(event) {
        this.setState({ enteredComponentBundle: event.target.value });
    }

    changeComponentGroup(event) {
        this.setState({ enteredComponentGroup: event.target.value });
    }

    changeComponentAction(event) {
        this.setState({ enteredComponentAction: event.target.value });
    }

    changeComponentLabel(event) {
        this.setState({ enteredComponentLabel: event.target.value });
    }

    changeComponentDisplaySequence(event) {
        this.setState({ enteredComponentDisplaySequence: event.target.value });
    }

    resetForm() {
        this.setState({
            ComponentTypes: [],
            selectedComponentTypeId: 0,
            selectedComponentTypeName: "",
            enteredComponentName: "",
            enteredComponentShortName: "",
            enteredComponentDescription: "",
            enteredComponentFeature: "",
            enteredComponentBundle: "",
            enteredComponentGroup: "",
            enteredComponentAction: "",
            enteredComponentLabel: "",
            enteredComponentDisplaySequence: 0,
        });
    }

    submitForm() {
        var sendData = {
            'TypeId': this.state.selectedComponentTypeId,
            'Type': this.state.selectedComponentTypeName,
            'Name': this.state.enteredComponentName,
            'ShortName': this.state.enteredComponentShortName,
            'Description': this.state.enteredComponentDescription,
            'Feature': this.state.enteredComponentFeature,
            'Bundle': this.state.enteredComponentBundle,
            'Group': this.state.enteredComponentGroup,
            'Action': this.state.enteredComponentAction,
            'Label': this.state.enteredComponentLabel,
            'DisplaySequence': this.state.enteredComponentDisplaySequence,
            'IsActive': true
        }
        authorizerService.postComponent(sendData)
            .then(response => {
                this.resetForm();
                this.notify(response.data.StatusCode);
            })
            .catch(e => {
                this.notify(e.response.status, e.response.data);
                console.log(e);
            });
    }

    notify(statusCode, message) {
        if (statusCode === 200) {
            toast("Component Added Successfully!");
        }
        if (statusCode === 401) {
            toast(message);
        }
    }

    render() {
        return (
            <div className=" col-12 col-lg-6 login-card mt-2 hv-center">
                <div className="text-center text-primary">
                    <h4 >Add Component</h4>
                </div>
                <ToastContainer />
                <form>
                    <div className="form-group row text-left">
                        <div className="col-lg-6">
                            <label>Type</label>
                            {/*<Select*/}
                            {/*    isMulti={true}*/}
                            {/*    options={this.state.ComponentTypes} // Options to display in the dropdown*/}
                            {/*    selectedValues={this.state.selectedComponentType} // Preselected value to persist in dropdown*/}
                            {/*    onChange={this.changeComponentType} // Function will trigger on change event                               */}
                            {/*    placeholder="Select Type"*/}
                            {/*    name="ComponentTypes"*/}
                            {/*    className="basic-multi-select"*/}
                            {/*    classNamePrefix="select"*/}
                            {/*/>*/}
                            <Select
                                className="basic-single"
                                name="ComponentTypes"
                                classNamePrefix="select"
                                options={this.state.ComponentTypes}
                                onChange={this.changeComponentType}
                                placeholder="Select Type"
                            />
                        </div>
                        <div className="col-lg-6">
                            <label>Name</label>
                            <input type="text" className="form-control" name="ComponentName" value={this.state.enteredComponentName} onChange={this.changeComponentName} />
                        </div>
                        <div className="col-lg-6">
                            <label>ShortName</label>
                            <input type="text" className="form-control" name="ComponentShortName" value={this.state.enteredComponentShortName} onChange={this.changeComponentShortName} />
                        </div>
                        <div className="col-lg-6">
                            <label>Description</label>
                            <input type="text" className="form-control" name="ComponentDescription" value={this.state.enteredComponentDescription} onChange={this.changeComponentDescription} />
                        </div>
                        <div className="col-lg-6">
                            <label>Feature</label>
                            <input type="text" className="form-control" name="ComponentFeature" value={this.state.enteredComponentFeature} onChange={this.changeComponentFeature} />
                        </div>
                        <div className="col-lg-6">
                            <label>Bundle</label>
                            <input type="text" className="form-control" name="ComponentBundle" value={this.state.enteredComponentBundle} onChange={this.changeComponentBundle} />
                        </div>
                        <div className="col-lg-6">
                            <label>Group</label>
                            <input type="text" className="form-control" name="ComponentGroup" value={this.state.enteredComponentGroup} onChange={this.changeComponentGroup} />
                        </div>
                        <div className="col-lg-6">
                            <label>Action</label>
                            <input type="text" className="form-control" name="ComponentAction" value={this.state.enteredComponentAction} onChange={this.changeComponentAction} />
                        </div>
                        <div className="col-lg-6">
                            <label>Label</label>
                            <input type="text" className="form-control" name="ComponentLabel" value={this.state.enteredComponentLabel} onChange={this.changeComponentLabel} />
                        </div>
                        <div className="col-lg-6">
                            <label>Display Sequence</label>
                            <input type="number" className="form-control" name="ComponentDisplaySequence" value={this.state.enteredComponentDisplaySequence} onChange={this.changeComponentDisplaySequence} />
                        </div>
                    </div>
                    <div className="form-group row text-left">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-2 text-center">
                            <button className="btn btn-md btn-primary" type="button" onClick={this.submitForm}>Save</button>
                        </div>
                        <div className="col-lg-2 text-center">
                            <button className="btn btn-md btn-primary" type="button" onClick={this.resetForm}>Reset</button>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Component;
