import React, { useState, useEffect } from "react";
import {
    APIGATEWAY,
    LOGIN_ENDPOINT,
    LOGIN_CODEVERIFICATION,
    IDENTITY_CLIENT_ID,
    APIKEY,
} from "../../constants/AppConfig";
import HttpHelper from "../../helpers/HttpHelper";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import "../views/style.css";
function LoginForm(props) {
    const navigate = useNavigate();
    const [state, setState] = useState({
        email: "",
        password: "",
        Code: "",
    });
    const [isMFA, setIsMFA] = useState(false);
    const handleChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };
    useEffect(() => {
        console.log("ismfa", isMFA);
    }, [isMFA]);
    const handleSubmitClick = (e) => {
        e.preventDefault();
        if (state.email.length && state.password.length) {
            sendDetailsToServer();
        } else {
            alert("No credentials provided");
        }
    };

    const handleSignUpClick = (e) => {
        navigate("/register");
    };

    //const handleForgetClick = (e) => {
    //    navigate("/forgetPassword");
    //}
    const handleSubmitCodeClick = (e) => {
        e.preventDefault();
        if (state.email.length && state.Code.length) {
            sendCodeDetailsToServer();
        } else {
            alert("Invalid Code. Please retry with valid code.");
        }
    };
    const sendCodeDetailsToServer = () => {
        if (state.email.length && state.password.length) {
            const loginObj = {
                Username: state.email,
                Code: state.Code,
                ClientId: IDENTITY_CLIENT_ID,
                DeviceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            };
            console.log(loginObj);
            let url = APIGATEWAY + LOGIN_CODEVERIFICATION;
            let helper = new HttpHelper();
            axios.post(url, loginObj,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: APIKEY,
                    }
                }
            ).then((res) => {
                console.log(res);
                if (res.data != null) {
                    var jsr = res.data;
                    document.cookie = "IdentiTeamToken=" + jsr.AuthToken;
                    document.cookie = "IdentiTeamRefreshToken=" + jsr.RefreshToken;
                    navigate("/loginsuccess");
                } else {
                    alert("failed");
                }
            });
        } else {
            alert("Please enter valid username and password");
        }
    };
    const sendDetailsToServer = () => {
        if (state.email.length && state.password.length) {
            const loginObj = {
                Username: state.email,
                Password: state.password,
                ClientId: IDENTITY_CLIENT_ID,
                DeviceId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            };
            console.log(loginObj);
            let url = APIGATEWAY + LOGIN_ENDPOINT;
            let helper = new HttpHelper();
            axios.post(url, loginObj, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            }).then((res) => {
                console.log(res);
                if (res.data != null) {
                    var jsr = res.data;
                    document.cookie = "IdentiTeamToken=" + jsr.AuthToken;
                    document.cookie = "IdentiTeamRefreshToken=" + jsr.RefreshToken;
                    if (jsr.Code == "" || jsr.Code == null || jsr.Code == undefined) {
                        navigate("/loginsuccess");
                    } else {
                        setIsMFA(true);
                        console.log(isMFA);
                    }
                } else {
                    alert("Login failed");
                }
            });
        } else {
            alert("Please enter valid username and password");
        }
    };
    return (
        <div className="card col-12 col-lg-5 login-card mt-2 hv-center">
            <form>
                <div className={`form-group text-left`}>
                    <label className={`infomessage ${!isMFA ? "d-none" : ""}`}>
                        <input type="checkbox" class="alertCheckbox" autocomplete="off" />
                        <div class="alert notice">
                            <span class="alertClose">X</span>
                            <span class="alertText">
                                Please enter the verification code sent to below email
                                <br class="clear" />
                            </span>
                        </div>
                    </label>
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        value={state.email}
                        readOnly={isMFA}
                        onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                    </small>
                </div>
                <div className={`form-group text-left ${!isMFA ? "d-none" : ""}`}>
                    <label htmlFor="code">Code</label>
                    <input
                        type="text"
                        className="form-control"
                        id="Code"
                        placeholder="Code"
                        value={state.Code}
                        onChange={handleChange}
                    />
                </div>
                <div className={`form-group text-left ${isMFA ? "d-none" : ""}`}>
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Password"
                        value={state.password}
                        onChange={handleChange}
                    />
                </div>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSignUpClick}
                >
                    Sign Up
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                    type="button"
                    className={`btn btn-primary ${isMFA ? "d-none" : ""}`}
                    onClick={handleSubmitClick}
                >
                    Login
                </button>
                <button
                    type="submit"
                    className={`btn btn-primary ${!isMFA ? "d-none" : ""}`}
                    onClick={handleSubmitCodeClick}
                >
                    Submit
                </button>
            </form>
        </div>
    );
}
export default LoginForm;
