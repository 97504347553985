import React from "react";
function Header() {
  return (
    <nav className="navbar navbar-dark bg-primary">
      <div className="row col-12 d-flex justify-content-center text-white">
        <span className="h3">SSTECH Identity UI Client</span>
      </div>
    </nav>
  );
}
export default Header;
