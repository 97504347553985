import axios from "axios";
export const RecipientBaseUrl = "https://portal.microservices.sstech.us:8081/api";//https://localhost:7153/api

const token = ('; ' + document.cookie).split(`; IdentiTeamToken=`).pop().split(';')[0];
export default axios.create({
    baseURL: RecipientBaseUrl,
    headers: {
        "Content-type": "application/json",
         Authorization: `Bearer ${token}` 
    }
});