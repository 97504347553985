import axios from "axios";
export const TemplateBaseUrl = "https://portal.microservices.sstech.us:8082/api";//https://localhost:7178/api

const token = ('; ' + document.cookie).split(`; IdentiTeamToken=`).pop().split(';')[0];
export default axios.create({
    baseURL: TemplateBaseUrl,
    headers: {
        "Content-type": "application/json",
         Authorization: `Bearer ${token}` 
    }
});