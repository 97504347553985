import React from "react";
function SideNav() {
    return (
        <div className="sidenav">
            {/*<a href="/">Login</a>*/}
            {/*<a href="/register">Register</a>*/}
            {/*<a href="/resetPassword">Reset Password</a>*/}
            <a href="/SendSms">Send SMS</a>
            <a href="/SendEmail">Send Email</a>
            <a href="/Component">Component</a>
            <a href="/GroupPermission">Group Permission</a>
        </div>
    );
}
export default SideNav;

