import http from "../helpers/http-authorizer";

class authorizerService {
    getComponentTypes() {
        return http.get("/ComponentTypes");
    }

    postComponent(sendData) {
        return http.post("/Components", sendData);
    }

    getGroups() {
        return http.get("/Groups");
    }

    getComponents() {
        return http.get("/Components");
    }

    postGroupPermission(sendData) {        
        return http.post("/GroupPermissions/BulkInsert", sendData);
    }
}

export default new authorizerService();