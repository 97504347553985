import axios from "axios";
export const Authorizer = "https://portal.microservices.sstech.us:8084/api"; //"https://localhost:5021/api";  

const token = ('; ' + document.cookie).split(`; IdentiTeamToken=`).pop().split(';')[0];
export default axios.create({
    baseURL: Authorizer,
    headers: {
        "Content-type": "application/json",
         Authorization: `Bearer ${token}` 
    }
});