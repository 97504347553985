import React from "react";
import { APIGATEWAY, GITHUB_LOGIN_ENDPOINT } from "../../constants/AppConfig";
import HttpHelper from "../../helpers/HttpHelper";

class LoginWithGithub extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoggedIn: false };
    this.state = { greeting: "" };
  }

  componentDidMount() {
    let helper = new HttpHelper();
    let githubLoginUrl = APIGATEWAY + GITHUB_LOGIN_ENDPOINT;
    helper
      .fetchChallenge(githubLoginUrl, "POST")
      .then(function (challengeResult) {});
  }

  render() {
    return <div>{this.state.greeting}</div>;
  }
}

export default LoginWithGithub;
