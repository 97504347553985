import axios from "axios";

export const CommunicationBaseUrl = "https://portal.microservices.sstech.us:8080/api";//https://localhost:7142/api


const token = ('; ' + document.cookie).split(`; IdentiTeamToken=`).pop().split(';')[0];
export default axios.create({
    baseURL: CommunicationBaseUrl,
    headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`
    }
});