import http from "../helpers/http-recipient";

class recipientService {
    getGroups() {        
        return http.get("/Group");
    }

    getRecipients() {
        return http.get("/Recipient");
    }

    postRecipient(data) {
        return http.post("/Recipient/RecipientsByGroups",data);
    }

    
}

export default new recipientService();