import React from "react";
import { APIGATEWAY, ADO_LOGIN_ENDPOINT } from "../../constants/AppConfig";
import HttpHelper from "../../helpers/HttpHelper";
class LoginWithAdo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoggedIn: false };
    this.state = { greeting: "" };
  }

  componentDidMount() {
    debugger
    let helper = new HttpHelper();
    let facebookLoginUrl = APIGATEWAY + ADO_LOGIN_ENDPOINT;
    helper
      .fetchChallenge(facebookLoginUrl, "POST")
      .then(function (challengeResult) {});
  }

  render() {
    return <div>{this.state.greeting}</div>;
  }
}

export default LoginWithAdo;
