import http from "../helpers/http-communication";

class communicationService {
    getLaguages() {        
        return http.post("/Message/GetLanguage");
    }     

    postMessageSend(sendData) {        
        return http.post("/Message/SendDispatch", sendData);
    }

    postEmailSend(sendData) {
        return http.post("Email/SendGrid", sendData);
    }
}

export default new communicationService();