import React from "react";
import {
  APIGATEWAY,
  MICROSOFT_LOGIN_ENDPOINT,
} from "../../constants/AppConfig";
import HttpHelper from "../../helpers/HttpHelper";
class LoginWithMicrosoft extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoggedIn: false };
    this.state = { greeting: "" };
  }

  componentDidMount() {
    let helper = new HttpHelper();
    let microsoftLoginUrl = APIGATEWAY + MICROSOFT_LOGIN_ENDPOINT;
    helper
      .fetchChallenge(microsoftLoginUrl, "POST")
      .then(function (challengeResult) {});
  }

  render() {
    return <div>{this.state.greeting}</div>;
  }
}

export default LoginWithMicrosoft;
