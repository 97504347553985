import React, { useState } from "react";
import {
    APIGATEWAY,
    RESET_PASSWORD_ENDPOINT,
    APIKEY,
} from "../../constants/AppConfig";
import { useNavigate } from "react-router-dom";
import { IDENTITY_CLIENT_ID } from "../../constants/AppConfig";

function ResetPassword(props) {
    const navigate = useNavigate();
    const [state, setState] = useState({
        email: "",
        password: "",
        phoneNumber: "",
    });
    const handleChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSubmitClick = (e) => {
        e.preventDefault();
        if (state.password === state.confirmPassword) {
            sendDetailsToServer();
        } else {
            alert("Passwords do not match");
        }
    };

    const sendDetailsToServer = () => {
        
        if (state.email.length && state.password.length) {
            //props.showError(null);

            const regObj = {
                UserName: state.email,
                Email: state.email,
                Password: state.password,
                PhoneNumber: state.phoneNumber,
                ClientId: IDENTITY_CLIENT_ID,
            };

            let url = APIGATEWAY + RESET_PASSWORD_ENDPOINT;
            const token = ('; ' + document.cookie).split(`; IdentiTeamToken=`).pop().split(';')[0];
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    ApiKey: APIKEY,
                    Authorization: "Bearer " + token+ "",
                },
                body: JSON.stringify(regObj),
            }).then(function (response) {
                if (response.ok) {
                    navigate("/");
                } else {
                    response.text().then(function (text) {
                        let asJson = JSON.parse(text);
                        alert(asJson.Message);
                    });
                }
            });
        } else {
            alert("Please enter valid username and password");
        }
    };
    return (
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            <form>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        value={state.email}
                        onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                    </small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPhoneNumber">Phone Number</label>
                    <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        placeholder="Enter Phone Number"
                        value={state.phoneNumber}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Password"
                        value={state.password}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">Confirm Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        value={state.confirmPassword}
                        onChange={handleChange}
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >
                    Submit
                </button>
            </form>
        </div>
    );
}
export default ResetPassword;
