import "./App.css";
import Header from "./components/Header";
import UserRegistrationForm from "./components/views/UserRegistrationForm";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginSuccess from "./components/views/loginSuccess.js";
import LoginWithGoogle from "./components/views/loginwithGoogle";
import LoginWithFacebook from "./components/views/loginwithFacebook";
import LoginWithMicrosoft from "./components/views/loginwithMicrosoft";
import LoginWithGithub from "./components/views/loginWithGithub";
import LoginWithAdo from "./components/views/loginWithAdo";
import OidcLogin from "./components/views/OidcLogin";
import SendSms from "./components/views/SendSms";
import SendEmail from "./components/views/SendEmail";
import ResetPassword from "./components/views/ResetPassword";
import UserUpdateForm from "./components/views/UserUpdateForm";
import { useAuth } from "react-oidc-context";
import LoginForm from "./components/views/LoginForm";
import LoginChoices from "./components/views/loginChoices";
import SideNav from "./components/SideNav";
import Component from "./components/views/Component";
import GroupPermission from "./components/views/GroupPermission";


function App() {
    const auth = useAuth();

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    if (auth.isAuthenticated) {
        // console.log("You are authenticated");
        // console.log(auth.user.access_token);
    }

    return (
        <div className="App">
            <Header />
            <SideNav />
            <div className="container d-flex align-items-center flex-column">
                <Router>
                    <Routes>
                        <Route exact path="/" element={<LoginChoices />} />
                        <Route path="/login" element={<LoginForm />} />
                        <Route path="/loginsuccess" element={<LoginSuccess />} />
                        <Route path="/register" element={<UserRegistrationForm />} />
                        <Route path="/update" element={<UserUpdateForm />} />
                        <Route path="/googlelogin" element={<LoginWithGoogle />} />
                        <Route path="/facebooklogin" element={<LoginWithFacebook />} />
                        <Route path="/microsoftlogin" element={<LoginWithMicrosoft />} />
                        <Route path="/githublogin" element={<LoginWithGithub />} />
                        <Route path="/microsoftlogin" element={<LoginWithMicrosoft />} />
                        <Route path="/adologin" element={<LoginWithAdo />} />
                        <Route path="/SendSms" element={<SendSms />} />
                        <Route path="/SendEmail" element={<SendEmail />} />
                        {/*<Route path="/sendGroupSms" element={<SendGroupSMS />} />*/}
                        <Route path="/resetPassword" element={<ResetPassword />} />
                        <Route path="/Component" element={<Component />} />
                        <Route path="/GroupPermission" element={<GroupPermission />} />
                    </Routes>
                </Router>
            </div>
        </div>
    );
}

export default App;
