import React, { useState } from "react";
import Select from 'react-select';
import recipientService from "../../services/recipient.service";
import templateService from "../../services/template.service";
import communicationService from "../../services/communication.service";
import HTMLReactParser from 'html-react-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class SendEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            recipients: [],
            laguages: [],
            templates: [],
            message: "",
            subject: "",
            selectedGroups: [],
            selectedRecipients: [],
            selectedTemplate: "",
            selectedLaguage: "",
            selectedPreferenceType: ""

        };
        this.changeGroup = this.changeGroup.bind(this);
        this.changeRecipient = this.changeRecipient.bind(this);
        this.changeTemplate = this.changeTemplate.bind(this);
        this.changeLaguage = this.changeLaguage.bind(this);
        this.changeSubject = this.changeSubject.bind(this);
        this.changeMessage = this.changeMessage.bind(this);
        this.changeRadioValue = this.changeRadioValue.bind(this);
        this.clickSendEmail = this.clickSendEmail.bind(this);
        this.resetSendEmail = this.resetSendEmail.bind(this);
        this.notify = this.notify.bind(this);
    }

    componentDidMount() {
        this.retrieveLaguages();
        this.retrieveTemplates();
        this.retrieveGroups();
        this.retrieveRecipent();
    }

    retrieveGroups() {
        recipientService.getGroups()
            .then(response => {
                const jsonData = response.data.Data;
                const options = jsonData.map(d => { return { label: d.Name, value: d.Id } });
                this.setState({
                    groups: options
                });

            })
            .catch(e => {
                this.notify(e.response.status, e.message);
                console.log(e);
            });
    }

    changeGroup(selectedGroups) {
        this.setState({ selectedGroups: selectedGroups.map(x => x.value) });
        this.retrieveRecipent(selectedGroups.map(x => x.value));
    }

    retrieveRecipent(selectedGroups) {        
        this.recipients = [];
        const options = [];
        if (selectedGroups === undefined) {
            recipientService.getRecipients()
                .then(response => {                    
                    const recipients = response.data.Data;
                    for (var i = 0; i < recipients.length; i++) {                        
                        options.push({
                            label: recipients[i].FirstName + " " + recipients[i].LastName,
                            value: recipients[i].Id,
                        });
                    }
                    this.setState({
                        recipients: options
                    });
                })
                .catch(e => {                    
                    this.notify(e.response.status, e.message);
                    console.log(e);
                });
        } else {
            recipientService.postRecipient(selectedGroups)
                .then(response => {
                    const jsonData = response.data.Data;
                    for (var j = 0; j < jsonData.length; j++) {
                        const recipients = jsonData[j].Recipients;
                        for (var i = 0; i < recipients.length; i++) {
                            options.push({
                                label: recipients[i].FirstName + " " + recipients[i].LastName,
                                value: recipients[i].Id,
                            });
                        };
                    }
                    this.setState({
                        recipients: options
                    });
                })
                .catch(e => {                    
                    this.notify(e.response.status, e.message);
                    console.log(e);
                });
        }
    }

    changeRecipient(selectedRecipients) {
        this.setState({ selectedRecipients: selectedRecipients.map(x => x.value) });
    }

    retrieveTemplates() {
        templateService.getTemplates()
            .then(response => {
                const jsonData = response.data.Data;
                const options = jsonData.map(d => { return { label: d.Name, value: d } });
                this.setState({
                    templates: options
                });

            })
            .catch(e => {
                this.notify(e.response.status, e.message);
                console.log(e);
            });
    }

    changeTemplate(event) {
        this.setState({ selectedTemplate: event.value.Name });
        this.setState({ message: event.value.TemplateHtml });
    }

    retrieveLaguages() {
        communicationService.getLaguages()
            .then(response => {
                const jsonData = response.data.Data;
                const options = jsonData.map(d => { return { label: d.Name, value: d.Code } });
                this.setState({
                    laguages: options
                });

            })
            .catch(e => {
                this.notify(e.response.status, e.message);
                console.log(e);
            });
    }

    changeLaguage(event) {
        this.setState({ selectedLaguage: event.value });
    }

    changeSubject(event) {
        this.setState({ subject: event.target.value });
    }

    changeMessage(event) {
        this.setState({ message: event.target.value });
    }

    parseHtml() {
        const html = '' + this.state.message + '';
        return HTMLReactParser(html, { trim: true });
    }

    changeRadioValue(event) {
        this.setState({ selectedPreferenceType: event.target.value })
    }

    clickSendEmail() {

        var sendData = {
            'Template': this.state.selectedTemplate,
            'PreferenceType': this.state.selectedPreferenceType === '' ? 'Email' : this.state.selectedPreferenceType,
            'IndRecipientIds': this.state.selectedRecipients,
            'GroupIds': this.state.selectedGroups,
            'FromLanguage': this.state.selectedLaguage,
            'Message': this.state.message,
            'Subject': this.state.subject,
        }
        communicationService.postEmailSend(sendData)
            .then(response => {
                this.notify(response.data.StatusCode);
            })
            .catch(e => {
                this.notify(e.response.status, e.message);
                console.log(e);
            });

    }

    resetSendEmail() {
        this.setState({
            groups: [],
            recipients: [],
            laguages: [],
            templates: [],
            message: "",
            selectedGroups: [],
            selectedRecipients: [],
            selectedTemplate: "",
            selectedLaguage: "",
            selectedPreferenceType: "",
            subject: "",
        });
    }

    notify(statusCode, message) {
        if (statusCode === 200) {
            toast("Email Send Successfully!");
            this.resetSendEmail();
        }

        if (statusCode === 401) {
            toast(message);
        }
    }


    render() {
        return (
            <div className=" col-12 col-lg-6 login-card mt-2 hv-center">
                <div className="text-center text-primary">
                    <h4 >Send Email</h4>
                </div>
                <ToastContainer />
                <form>
                    <div className="form-group row text-left">
                        <div className="col-lg-6">
                            <label>Select Group</label>
                            <Select
                                isMulti={true}
                                options={this.state.groups} // Options to display in the dropdown
                                selectedValues={this.state.selectedGroup} // Preselected value to persist in dropdown
                                onChange={this.changeGroup} // Function will trigger on change event                               
                                placeholder="Select Group"
                                name="groups"
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </div>
                        <div className="col-lg-6">
                            <label>Select Recipient</label>
                            <Select
                                isMulti={true}
                                options={this.state.recipients} // Options to display in the dropdown
                                selectedValues={this.state.selectedRecipient} // Preselected value to persist in dropdown
                                onChange={this.changeRecipient} // Function will trigger on change event                               
                                placeholder="Select Recipient"
                                name="recipients"
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </div>
                        <div className="col-lg-6">
                            <label>Language</label>
                            <Select
                                className="basic-single"
                                name="language"
                                classNamePrefix="select"
                                options={this.state.laguages}
                                onChange={this.changeLaguage}
                                placeholder="Select Language"
                            />
                        </div>
                        <div className="col-lg-6">
                            <label>Template</label>
                            <Select
                                className="basic-single"
                                name="template"
                                classNamePrefix="select"
                                options={this.state.templates}
                                onChange={this.changeTemplate}
                                placeholder="Select Template"
                            />
                        </div>
                    </div>
                    <div className="form-group row" onChange={this.changeRadioValue}>
                        <div className="col-xs-2 col-md-3">
                            <input class="form-check-input" value="text" type="radio" name="flexRadioDefault" checked />
                            <label class="form-check-label"> Text  </label>
                        </div>
                        <div className="col-xs-3 col-md-3">
                            <input class="form-check-input" value="voice" type="radio" name="flexRadioDefault" />
                            <label class="form-check-label"> Voice </label>
                        </div>
                        <div className="col-xs-3 col-md-6">
                            <input class="form-check-input" type="radio" value="recipient" name="flexRadioDefault" />
                            <label class="form-check-label"> Recipient Preference </label>
                        </div>
                    </div>
                    <div className="form-group row text-left">
                        <div className="col-lg-12 col-md-12">
                            <label for="ex1">Subject</label>
                            <input type="text" className="form-control" id="subject"
                                name="subject"
                                value={this.state.subject}
                                onChange={this.changeSubject}
                            />
                        </div>
                    </div>
                    <div className="form-group row text-left">
                        <div className="col-lg-12 col-md-12">
                            <label for="ex1">Message</label>
                            <textarea className="form-control" id="message"
                                name="message"
                                value={this.state.message}
                                onChange={this.changeMessage}
                                rows="7" cols="50"
                            />
                        </div>
                        <div className="col-lg-12 col-md-12 border border-primary" width="100px;" height="200px;" >{this.parseHtml()}</div>
                    </div>
                    <div className="form-group row text-left">
                        <div className="col-lg-12 text-center">
                            <button className="btn btn-md btn-primary" id="ex1" type="button" onClick={this.clickSendEmail}>Send</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default SendEmail;
