export const RecipientBaseUrl = "https://portal.microservices.sstech.us:8081"; //"https://localhost:7153";
export const CommunicationBaseUrl = "https://portal.microservices.sstech.us:8080"; //"https://localhost:7142";
export const TemplateBaseUrl = "https://portal.microservices.sstech.us:8082"; //"https://localhost:7178";
export const APIGATEWAY = "https://portal.microservices.sstech.us:8083";  //"https://localhost:5001";
export const COMM_SERVICE_URL = "https://localhost:7001";
export const REGISTER_ENDPOINT = "/api/v1/User/CreateUser";
export const UPDATE_ENDPOINT = "/api/v1/User/UpdateUser/";
export const RESET_PASSWORD_ENDPOINT = "/api/v1/User/ResetPassword";
export const APIKEY = "07a58021-8023-4bc8-875d-396ca121d395";
export const LOGIN_ENDPOINT = "/api/v1/Auth/mfalogin";
export const LOGIN_CODEVERIFICATION = "/api/v1/Auth/mfaValidateCode";
export const LOGOUT_ENDPOINT = "/api/v1/Auth/logout";
export const CHECKAUTH_ENDPOINT = "/api/v1/Identity";
export const GOOGLE_LOGIN_ENDPOINT = "/api/v1/Auth/google-login";
export const FACEBOOK_LOGIN_ENDPOINT = "/api/v1/Auth/facebook-login";
export const ADO_LOGIN_ENDPOINT = "/api/v1/Auth/azure-login";
export const MICROSOFT_LOGIN_ENDPOINT = "/api/v1/Auth/microsoft-login";
export const CLAIMS_ENDPOINT = "/api/v1/Claims/identity-claims";
export const GITHUB_CLIENT_ID = "6c38acd931566001ffa7";
export const GITHUB_LOGIN_ENDPOINT = "/api/v1/Auth/github-login";
export const SEND_EMAIL_ENDPOINT = COMM_SERVICE_URL + "/api/email/sendgrid";
export const SEND_SMS_ENDPOINT = COMM_SERVICE_URL + "/api/Message/SendSms";

export const IDENTITY_CLIENT_ID = "react-frontend";

/// These are user manager settings that get used in the constructor for AuthProvider in Index.Js
export const OIDC_CONFIG = {
    authority: "https://portal.microservices.sstech.us:8083", //"https://localhost:5001",
    client_id: "react-frontend",
    redirect_uri: "https://portal.microservices.sstech.us/loginSuccess",   //"http://localhost:3000/loginSuccess", 
    response_type: "code",
    scope: "openid profile offline_access sstechidentityapi IdentityServerApi",
    post_logout_redirect_uri: "https://portal.microservices.sstech.us",   //"http://localhost:3000", 
    loadUserInfo: true,
};
