import React from "react";
import { useNavigate } from "react-router-dom";

function LoginChoices() {
    const navigate = useNavigate();

    const goToLogin = () => {
        navigate("/login");
    };

    const goToGoogleLogin = () => {
        navigate("/googlelogin");
    };

    const goToFacebookLogin = () => {
        navigate("/facebooklogin");
    };

    const goToGitHubLogin = () => {
        navigate("/githublogin");
    };
    const goToMicrosoftLogin = () => {
        navigate("/microsoftlogin");
    };

    return (
        <div className="card col-12 col-lg-4 login-card mt-2 hv-center">
            <form>
                <div className="form-group text-left">
                    <label>Login</label>
                    <br />
                    <button className="btn btn-primary" onClick={goToLogin}>
                        Local Account
                    </button>
                </div>
                <div className="form-group text-left">
                    <label>Login With Google</label>
                    <br />
                    <button className="btn btn-primary" onClick={goToGoogleLogin}>
                        Use Google
                    </button>
                </div>
                <div className="form-group text-left">
                    <label>Login With Facebook</label>
                    <br />
                    <button className="btn btn-primary" onClick={goToFacebookLogin}>
                        Use Facebook
                    </button>
                </div>
                <div className="form-group text-left">
                    <label>Login With GitHub</label>
                    <br />
                    <button className="btn btn-primary" onClick={goToGitHubLogin}>
                        Use GitHub
                    </button>
                </div>
                <div className="form-group text-left">
                    <label>Login With Microsoft</label>
                    <br />
                    <button className="btn btn-primary" onClick={goToMicrosoftLogin}>
                        Use Microsoft
                    </button>
                </div>
            </form>
        </div>
    );
}

export default LoginChoices;
