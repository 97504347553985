import React from "react";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authorizerService from "../../services/authorizer.service";



class GroupPermission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            components: [],
            groups: [],
            selectedGroups: [],
            selectedAllowedList: [],
            selectedDeniedList: [],
            masterAllowedChecked: false,
            masterDeniedChecked: false,

        };
        this.changeGroup = this.changeGroup.bind(this);
        this.notify = this.notify.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount() {
        this.retrieveGroups();
        this.retrieveComponents();

    }

    retrieveGroups() {
        authorizerService.getGroups()
            .then(response => {
                const jsonData = response.data.Data;
                const options = jsonData.map(d => { return { label: d.GroupName, value: d.GroupId } });
                this.setState({
                    groups: options
                });

            })
            .catch(e => {
                this.notify(e.response.status, e.message);
                console.log(e);
            });
    }

    changeGroup(selectedGroup) {
        this.setState({ selectedGroups: selectedGroup.map(x => x.value) });
    }

    retrieveComponents() {
        authorizerService.getComponents()
            .then(response => {
                const data = response.data.Data;
                this.setState({
                    components: data
                });

            })
            .catch(e => {
                this.notify(e.response.status, e.message);
                console.log(e);
            });
    }

    // Select/ UnSelect Table rows
    onMasterAllowedCheck(e) {
        let tempComponents = this.state.components;
        // Check/ UnCheck All Items
        tempComponents.map((component) => (component.selectedAllowed = e.target.checked));

        //Update State
        this.setState({
            masterAllowedChecked: e.target.checked,
            components: tempComponents,
            selectedAllowedList: this.state.components.filter((e) => e.selectedAllowed),
        });
    }

    // Select/ UnSelect Table rows
    onMasterDeniedCheck(e) {
        let tempComponents = this.state.components;
        // Check/ UnCheck All Items
        tempComponents.map((component) => (component.selectedDenied = e.target.checked));

        //Update State
        this.setState({
            masterDeniedChecked: e.target.checked,
            components: tempComponents,
            selectedDeniedList: this.state.components.filter((e) => e.selectedDenied),
        });
    }

    // Update Component Item's state and Master Checkbox State
    onItemAllowedCheck(e, item) {
        let tempComponents = this.state.components;
        tempComponents.map((component) => {
            if (component.Id === item.Id) {
                component.selectedAllowed = e.target.checked;
            }
            return component;
        });

        //To Control Master Checkbox State
        const totalItems = this.state.components.length;
        const totalCheckedItems = tempComponents.filter((e) => e.selectedAllowed).length;

        // Update State
        this.setState({
            masterAllowedChecked: totalItems === totalCheckedItems,
            components: tempComponents,
            selectedAllowedList: this.state.components.filter((e) => e.selectedAllowed),
        });
    }

    onItemDeniedCheck(e, item) {
        let tempComponents = this.state.components;
        tempComponents.map((component) => {
            if (component.Id === item.Id) {
                component.selectedDenied = e.target.checked;
            }
            return component;
        });

        //To Control Master Checkbox State
        const totalItems = this.state.components.length;
        const totalCheckedItems = tempComponents.filter((e) => e.selectedDenied).length;

        // Update State
        this.setState({
            masterDeniedChecked: totalItems === totalCheckedItems,
            components: tempComponents,
            selectedDeniedList: this.state.components.filter((e) => e.selectedDenied),
        });
    }

    // Event to get selected rows(Optional)
    getSelectedRowsOfAllowed() {
        this.setState({
            selectedAllowedList: this.state.components.filter((e) => e.selected),
        });
    }

    // Event to get selected rows(Optional)
    getSelectedRowsOfDenied() {
        this.setState({
            selectedDeniedList: this.state.components.filter((e) => e.selected),
        });
    }

    resetForm() {
        this.setState({
            groups: [],
            selectedGroups: [],
        });
    }

    submitForm() {
        var sendData = {
            'AllowedComponentIds': this.state.selectedAllowedList.map(function (val) {
                return val.Id;
            }),
            'DeniedComponentIds': this.state.selectedDeniedList.map(function (val) {
                return val.Id;
            }),
            'GroupIds': this.state.selectedGroups,
            'IsActive': true
        }
        authorizerService.postGroupPermission(sendData)
            .then(response => {
                this.resetForm();
                this.notify(response.data.StatusCode);
            })
            .catch(e => {
                console.log(e);
                this.notify(e.response.status, e.response.data.Message);

            });
    }

    notify(statusCode, message) {
        if (statusCode === 200) {
            toast("Component Added Successfully!");
        }
        if (statusCode === 401) {
            toast(message);
        }
        if (statusCode === 400) {
            toast(message);
        }
        if (statusCode === 500) {
            toast(message);
        }
    }

    render() {
        return (
            <div className="col-lg-12 login-card mt-2 hv-center">
                <div className="text-center text-primary">
                    <h4>Group Permission</h4>
                </div>
                <ToastContainer />
                <form>
                    <div className="form-group row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            <label>Group</label>
                            <Select
                                isMulti={true}
                                className="basic-multi-select"
                                name="GroupPermissionGroups"
                                classNamePrefix="select"
                                options={this.state.groups}
                                onChange={this.changeGroup}
                                selectedValues={this.state.selectedGroup}
                                placeholder="Select Group"
                            />
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                    <div className="col-lg-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Id</th>
                                                <th scope="col">ShortName</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Feature</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Action</th>
                                                <th scope="col">Label</th>
                                                <th scope="col">Allowed
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={this.state.masterAllowedChecked}
                                                        id="masterallowedcheck"
                                                        onChange={(e) => this.onMasterAllowedCheck(e)}
                                                    />
                                                </th>
                                                <th scope="col">Denied
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={this.state.masterDeniedChecked}
                                                        id="masterdeniedcheck"
                                                        onChange={(e) => this.onMasterDeniedCheck(e)}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.components.map((component) => (
                                                <tr key={component.Id} className={component.selected ? "selected" : ""}>
                                                    <td>{component.Id}</td>
                                                    <td>{component.ShortName}</td>
                                                    <td>{component.Description}</td>
                                                    <td>{component.Feature}</td>
                                                    <td>{component.Type}</td>
                                                    <td>{component.Action}</td>
                                                    <td>{component.Label}</td>
                                                    <th scope="row">
                                                        <input
                                                            type="checkbox"
                                                            checked={component.selectedAllowed}
                                                            className="form-check-input"
                                                            id="rowcheck{component.Id}"
                                                            onChange={(e) => this.onItemAllowedCheck(e, component)}
                                                        />
                                                    </th>
                                                    <th scope="row">
                                                        <input
                                                            type="checkbox"
                                                            checked={component.selectedDenied}
                                                            className="form-check-input"
                                                            id="rowcheck{component.Id}"
                                                            onChange={(e) => this.onItemDeniedCheck(e, component)}
                                                        />
                                                    </th>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {/*<button*/}
                                    {/*    className="btn btn-primary"*/}
                                    {/*    onClick={() => this.getSelectedRowsOfAllowed()}*/}
                                    {/*>*/}
                                    {/*    Get Selected Items For Allowed {this.state.selectedAllowedList.length}*/}
                                    {/*</button>*/}
                                    {/*<button*/}
                                    {/*    className="btn btn-primary"*/}
                                    {/*    onClick={() => this.getSelectedRowsOfDenied()}*/}
                                    {/*>*/}
                                    {/*    Get Selected Items For Denied {this.state.selectedDeniedList.length}*/}
                                    {/*</button>*/}
                                    {/*<div className="row">*/}
                                    {/*    <b>All Row Items:</b>*/}
                                    {/*    <code>{JSON.stringify(this.state.components)}</code>*/}
                                    {/*</div>*/}
                                    {/*<div className="row">*/}
                                    {/*    <b>Selected Row Items For Allowed (Click Button To Get):</b>*/}
                                    {/*    <code>{JSON.stringify(this.state.selectedAllowedList)}</code>*/}
                                    {/*</div>*/}
                                    {/*<div className="row">*/}
                                    {/*    <b>Selected Row Items For Denied (Click Button To Get):</b>*/}
                                    {/*    <code>{JSON.stringify(this.state.selectedDeniedList)}</code>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-5"></div>
                        <div className="col-lg-2 text-center">
                            <button className="btn btn-md btn-primary btnmargin" type="button" onClick={this.submitForm}>Save</button>
                            <button className="btn btn-md btn-primary btnmargin" type="button" onClick={this.resetForm}>Reset</button>
                        </div>
                        <div className="col-lg-5"></div>
                    </div>
                </form>
            </div>
        );
    }
}

export default GroupPermission;
