import { APIKEY } from "../constants/AppConfig";
class HttpHelper {
    async fetchJson(url, httpMethod, payload, cookies) {
        if (cookies) {
            var jwtVal = cookies
                .split("; ")
                .find((row) => row.startsWith("IdentiTeamToken="))
                ?.split("=")[1];
        }

        let result;
        console.log(url);
        try {
            result = await fetch(url,
                {
                    method: httpMethod,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        ApiKey: APIKEY,
                        Authorization: "Bearer " + jwtVal,
                    },
                    body: httpMethod === "POST" ? JSON.stringify(payload) : null,
                    credentials: "include",
                    mode: "cors" 
                });
            console.log("helper result OK");
            return result;
        } catch (error) {
            console.log(error);
            // return new {
            //   error: error,
            // }();
        }
    }

    async fetchChallenge(url, httpMethod) {
        window.location.href =
            //url + "?returnurl=http://localhost:3000/loginsuccess";
            url + "?returnurl=https://portal.microservices.sstech.us/loginsuccess";
        // let result;

        // try {
        //   result = await fetch(url, {
        //     method: httpMethod,
        //     headers: { ApiKey: APIKEY },
        //     redirect: "follow",
        //   });

        //   console.log("headers.entries() below");
        //   for (var pair of result.headers.entries()) {
        //     console.log(pair[0] + " : " + pair[1]);
        //   }

        //   var loc = result.headers.get("Location");
        //   console.log(loc);
        // } catch (error) {}

        // return result;
    }
}

export default HttpHelper;
